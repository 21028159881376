import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui";

export const DialogBase = ({
  isSubmitting,
  submitFn,
  isOpen,
  setIsOpen,
  children,
}: {
  isSubmitting: boolean;
  submitFn: () => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) => {
  const ProceedButtons = () => {
    return (
      <div className="w-full flex space-x-8 justify-center py-2">
        <Button
          disabled={isSubmitting}
          className="px-12"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </Button>
        <Button
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={submitFn}
          className="px-12 bg-blue-600 hover:bg-blue-400 transition-colors"
        >
          Proceed
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(false)} modal>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
      >
        <DialogHeader>
          <DialogTitle className="flex text-2xl justify-center items-center flex-col gap-y-4 pb-2">
            Some images' quality might be poor
          </DialogTitle>
          <DialogDescription className="flex text-md text-justify flex-col pt-2 space-y-2 text-zinc-900 font-medium">
            The quality on some pictures might not be good enough for the AI to
            properly detect the writing. Make sure everything is clear and
            readable.
          </DialogDescription>
        </DialogHeader>
        <div className="max-h-80 overflow-y-auto">{children}</div>
        {ProceedButtons()}
      </DialogContent>
    </Dialog>
  );
};
