import {
  createInferenceSession,
  loadYoloV8,
  yoloV8CellConfig,
} from "@/app/(dashboard)/(game)/game/new_batch/utils/onnxUtilts";
import { useEffect, useState } from "react";

export const useYolo = ({ shouldLoad }: { shouldLoad: boolean }) => {
  const [session, setSession] = useState<any>(null);
  const [sessionTable, setSessionTable] = useState<any>(null);
  const [loading, setLoading] = useState<boolean | null>(null);
  const [windowLoaded, setWindowLoaded] = useState(false);
  const [loadingHasFailed, setLoadingHasFailed] = useState<true | null>(null);
  const [isLoadingModels, setIsLoadingModels] = useState(false);
  const { modelName, modelInputShape, topk, iouThreshold, scoreThreshold } =
    yoloV8CellConfig;

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!windowLoaded) return;
    if (!shouldLoad) return;
    const loadModels = async () => {
      let yolov8, yolov8table, nms;
      setIsLoadingModels(true);
      try {
        yolov8 = await loadYoloV8({ modelName, setLoading, modelInputShape });

        yolov8table = await loadYoloV8({
          modelName: "yolon8_table.onnx",
          setLoading,
          modelInputShape,
        });

        nms = await createInferenceSession({
          modelName: "nms-yolov8.onnx",
          setLoading,
        });
      } catch (e) {
        console.error(e);
        setLoadingHasFailed(true);
        setLoading(false);
        setIsLoadingModels(false);
        return;
      }
      setSession({ net: yolov8, nms: nms });
      setSessionTable({ net: yolov8table, nms: nms });
      setLoading(false);
      setIsLoadingModels(false);
    };
    loadModels();
  }, [windowLoaded, shouldLoad]);

  return {
    session,
    setSession,
    sessionTable,
    setSessionTable,
    loading,
    setLoading,
    windowLoaded,
    setWindowLoaded,
    modelName,
    modelInputShape,
    topk,
    iouThreshold,
    scoreThreshold,
    shouldLoad,
    loadingHasFailed,
    setLoadingHasFailed,
    isLoadingModels,
  };
};
