import React from "react";

export const horseSvg = (
  <svg
    id="spinnerSvg"
    xmlSpace="preserve"
    version="1.1"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      width: "20px",
      height: "20px",
    }}
    viewBox="0 0 116 211.25"
    x="0px"
    y="0px"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <defs>
      <style type="text/css">{`.fil0 { fill: black; }`}</style>
    </defs>
    <g>
      <path
        className="fil0"
        d="M53 11c56,-3 43,71 38,88 -4,-1 -7,-2 -10,-3 8,-27 11,-70 -28,-79 -1,-2 -1,-4 0,-6z"
      />
      <path
        className="fil0"
        d="M5 63c4,-4 30,-46 9,-30 6,-15 15,-17 23,-18 0,-2 -7,-7 -8,-15 6,2 16,5 22,11 -1,3 -2,8 0,9 33,9 36,41 27,71 0,2 -1,5 -2,7 6,2 11,4 16,6 3,3 6,6 4,9l-75 0c-9,-27 39,-52 26,-66 6,7 -5,21 -14,19 -4,4 -9,6 -15,11 1,3 -10,10 -12,7 1,-2 1,-1 1,-2 -1,0 -2,2 -3,2 -7,-9 -4,-16 1,-21zm21 -21c0,0 -1,-6 3,-8 4,-2 6,-1 6,-1 0,0 2,4 -9,9z"
      />
      <path
        className="fil0"
        d="M116 161l0 1 0 0c0,2 -1,5 -2,7l-111 0c-1,-2 -1,-5 -1,-7l0 0 0 -1 114 0z"
      />
      <path
        className="fil0"
        d="M93 137c11,1 20,10 22,21l-113 0c2,-11 11,-20 23,-21 -4,-3 -6,-8 -6,-13l0 0c0,-2 1,-5 2,-7l76 0c1,2 1,5 1,7l0 0c0,5 -2,10 -5,13z"
      />
    </g>
  </svg>
);
