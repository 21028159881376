"use client";

import { cn } from "@/lib/utils";
import { Codesandbox } from "lucide-react";

import Link from "next/link";

// images
// :
// boxes
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped_boxes.png"
// cropped
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped.png"
// edges
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped_edges.png"
// final_chess_moves
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/final_chess_moves.png"
// original
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/original.png"
// output
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage2/3e1b0702-360e-4823-b9ac-a42b9603506f/output.png"

interface LogoProps {
  isLoading: boolean;
}

export default function Logo({ isLoading }: LogoProps) {
  return (
    <Link href="/" className="flex items-center justify-between w-full px-2">
      <div className="sm-text-2xl md:text-4xl font-medium">XChess AI</div>
      <Codesandbox
        className={cn(
          "h-8 w-8 md:h-12 md:w-12",
          isLoading ? "animate-spin" : "",
        )}
      />
    </Link>
  );
}
