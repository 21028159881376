"use client";

import { ClientUser } from "@/types/ClientUser";
import React, { useContext, useState } from "react";

export interface UserContextInterface {
  imageFullScreen: boolean;
  setImageFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  user: ClientUser | null;
  setUser: React.Dispatch<React.SetStateAction<ClientUser | null>>;
}

export const UserContext = React.createContext<UserContextInterface | null>(
  null,
);

export function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [imageFullScreen, setImageFullScreen] = useState<boolean>(false);
  const [user, setUser] = useState<ClientUser | null>(null);

  const data: UserContextInterface = {
    imageFullScreen,
    setImageFullScreen,
    user,
    setUser,
  };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}

export function useUserContext(): UserContextInterface {
  const context = useContext<UserContextInterface | null>(UserContext);
  if (!context) {
    throw new Error(
      "useUserContext must be used withing a UserContextProvider",
    );
  }
  return context;
}
