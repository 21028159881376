"use client";
import ImageCard from "@/components/ImageCard";
import React, { useEffect, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import svgUpload from "@/assets/images/upload.svg";
import Image from "next/image";
import { createPortal } from "react-dom";
import { horseSvg } from "./svg";
interface DropzoneFormProps {
  onFilesAccepted: (acceptedFiles: File) => void;
  onFilesRejected: (fileRejections: FileRejection) => void;
  isSubmitting: boolean;
  isLoadingModels?: boolean;
}

const DropzoneForm = ({
  onFilesAccepted,
  onFilesRejected,
  isSubmitting,
  isLoadingModels,
}: DropzoneFormProps) => {
  const {
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
    fileRejections,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg", ".jfif", ".pjpeg", ".pjp"],
    },
    onDropAccepted: (acceptedFiles) => {
      onFilesAccepted(acceptedFiles[0]); // Pass accepted files to parent component
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      onFilesRejected(fileRejections[0]);
    },
    maxFiles: 1,
    noDragEventsBubbling: true,
    noKeyboard: true,
    disabled: isSubmitting || isLoadingModels,
  });

  const baseStyle =
    "w-full sm:w-max flex justify-center h-max rounded-lg cursor-pointer border-2 border-dashed border-blue-500";
  const acceptStyle =
    "border-4 border-dashed border-green-500 bg-gray-900 bg-opacity-25 opacity-75";
  const rejectStyle = "border-4 border-dashed border-red-500 opacity-75";

  const styleForDropzone = useMemo(
    () =>
      baseStyle +
      (isDragAccept ? acceptStyle : "") +
      (isDragReject ? rejectStyle : ""),
    [isFocused, isDragAccept, isDragReject],
  );

  // const PortalForDropzone = () => {
  //   return createPortal()
  // }

  const dropzoneText = isLoadingModels
    ? "Loading models..."
    : isSubmitting
      ? "File uploaded! Processing ensues..."
      : "Drag 'n' drop some files here, or click to select JPG/PNG files";

  return (
    <div {...getRootProps()} className={styleForDropzone}>
      <div
        title="Add image"
        className="flex flex-row gap-2 justify-center content-center items-center w-64 py-1 px-3 "
      >
        <input {...getInputProps()} />
        <Image src={svgUpload} alt="Add image" className="w-6 h-6" />
        <div className="flex justify-center text-center text-xs">
          {dropzoneText}
          {(isLoadingModels || isSubmitting) && <>{horseSvg}</>}
        </div>
      </div>
    </div>
  );
};

export default DropzoneForm;
