"use client";

import ChessboardUpload from "@/app/(dashboard)/components/ChessboardUpload";
import Logo from "@/app/(dashboard)/components/Logo";
import { ClientUser } from "@/types/ClientUser";
import { Menu, X } from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { UserAccountNav } from "./UserAccountNav";
import { useUserContext } from "./providers/user-provider";
import { cn, getCredits } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { isAdminUser } from "@/lib/utils";

import { BiSolidCoin } from "react-icons/bi";
import { SvgDiamond } from "./svgRepo";
import CreditsDisplay from "./CreditsDisplay";
interface NavbarProps {
  user: ClientUser | null;
}

function Navbar({ user }: NavbarProps) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Sticky Navbar
  const [sticky, setSticky] = useState(false);
  const pathname = usePathname();
  const userContext = useUserContext();
  var timer: any = null;
  const isAdmin = isAdminUser(user);

  const handleStickyNavbar = () => {
    if (window.scrollY >= 40) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleStickyNavbar);

    window.addEventListener(
      "scroll",
      function () {
        setHideNavbar(false);
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          setHideNavbar(true);
        }, 10000);
      },
      false,
    );
  }, []);

  if (userContext.imageFullScreen) {
    return;
  }

  return (
    <header
      className={cn(
        `header top-0 left-0 !z-10 flex w-full py-1 items-center bg-primary-foreground border-b ${
          sticky
            ? "!fixed !z-10 !bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm !transition drop-shadow-md "
            : "absolute"
        }`,
        sticky && hideNavbar && !isLoading ? "hidden" : "",
      )}
    >
      <div className="hidden h-full sm:flex sm:h-fit max-w-7xl mx-auto items-center justify-between w-full">
        <div className="flex w-full justify-between mx-5">
          {/* Primary menu and logo */}
          <div className="flex items-center justify-between space-x-2 w-full">
            {/* logo */}
            <div className="flex-col flex lg:flex-row items-center lg:space-x-3">
              <Logo isLoading={isLoading} />
              <ChessboardUpload user={user} setIsLoading={setIsLoading} />
            </div>
            {/* primary */}
            <div className="hidden sm:flex gap-8 items-center">
              <Link
                href="/"
                className={pathname === "/" ? "border-b-2 font-bold" : ""}
              >
                Home
              </Link>
              <Link
                className={pathname === "/games" ? "border-b-2 font-bold" : ""}
                href="/games"
              >
                Games
              </Link>
              {isAdmin && (
                <Link
                  className={
                    pathname === "/tournaments" ? "border-b-2 font-bold" : ""
                  }
                  href="/tournaments"
                >
                  Tournaments
                </Link>
              )}

              {!user && (
                <Link
                  className={
                    pathname === "/sign-in" ? "border-b-2 font-bold" : ""
                  }
                  href="/sign-in"
                >
                  Sign In
                </Link>
              )}
              {user && (
                <div className="flex items-center gap-4">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Link
                          className="flex gap-1 border-r-2 justify-center items-center border-slate-500 pr-4"
                          href="/pricing"
                        >
                          <SvgDiamond />
                          {getCredits(user.apiLimit)}
                        </Link>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Free credits: {user.apiLimit?.freeCredits ?? 0}</p>
                        <p>Plan credits: {user.apiLimit?.planCredits ?? 0}</p>
                        <p>Otp credits: {user.apiLimit?.otpCredits ?? 0}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <UserAccountNav user={user} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* mobile navigation */}
      <div className="sm:hidden flex-col w-full">
        <div className="flex w-full justify-between">
          {/* Mobile navigation toggle */}
          <div className="w-full items-center flex">
            <Logo isLoading={isLoading} />{" "}
          </div>

          <div className="sm:hidden flex items-center justify-end w-full py-1 pr-3 z-10 space-x-3">
            {user && <UserAccountNav user={user} />}
            <button onClick={() => setToggleMenu(!toggleMenu)}>
              {!toggleMenu && <Menu className="h-8 w-8" />}
              {toggleMenu && <X className="h-8 w-8" />}
            </button>
          </div>
          <div
            className={`sm:hidden fixed z-10 w-full bg-gray-100 overflow-hidden flex flex-col gap-12  origin-top duration-700 ${
              !toggleMenu ? "h-0 " : "h-fit py-4 "
            } ${!sticky ? "mt-24 " : "mt-12"}`}
          >
            <div className="px-8 w-full">
              <div className="flex flex-col gap-8 font-bold tracking-wider">
                <X
                  className=" cursor-pointer h-8 w-8"
                  onClick={() => setToggleMenu(!toggleMenu)}
                ></X>
                <Link
                  className={pathname === "/" ? "border-b font-bold" : ""}
                  href="/"
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Home
                </Link>
                <Link
                  className={pathname === "/games" ? "border-b font-bold" : ""}
                  href="/games"
                  onClick={() => setToggleMenu(!toggleMenu)}
                >
                  Games
                </Link>

                {isAdmin && (
                  <Link
                    className={
                      pathname === "/tournaments" ? "border-b font-bold" : ""
                    }
                    href="/tournaments"
                    onClick={() => setToggleMenu(!toggleMenu)}
                  >
                    Tournaments
                  </Link>
                )}

                {!!user && (
                  <Link
                    className={
                      pathname === "/collections/me" ? "border-b font-bold" : ""
                    }
                    href="/collections/me"
                    onClick={() => setToggleMenu(!toggleMenu)}
                  >
                    Collections
                  </Link>
                )}

                {!user && (
                  <Link
                    className={
                      pathname === "/sign-in" ? "border-b font-bold" : ""
                    }
                    href="/sign-in"
                    onClick={() => setToggleMenu(!toggleMenu)}
                  >
                    Sign In
                  </Link>
                )}
                {user && (
                  <Link
                    className={pathname === "/" ? "border-b font-bold" : ""}
                    href="/"
                    onClick={(event) => {
                      event.preventDefault();
                      signOut({
                        callbackUrl: `${window.location.origin}/sign-in`,
                      });
                    }}
                  >
                    Sign Out
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {!sticky && (
          <ChessboardUpload user={user} setIsLoading={setIsLoading} />
        )}
      </div>
    </header>
  );
}

export default Navbar;
