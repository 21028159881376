"use client";

import axios, { AxiosError } from "axios";
import { usePathname, useRouter } from "next/navigation";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import ImageUpload from "./ImageUpload";
import { toast } from "./ui/use-toast";
import { useProModal } from "@/hooks/useProModal";
import { useYolo } from "@/hooks/useYolo";
import {
  PossibleTooltipStates,
  ProblemMap,
  ProblemType,
  yoloType,
} from "@/app/(dashboard)/(game)/game/new_batch/utils/types";
import { detectImage } from "@/app/(dashboard)/(game)/game/new_batch/utils/detect";
import { tooltipsIconsAndTextsCell } from "@/app/(dashboard)/(game)/game/new_batch/utils/constants";
import { DialogBase } from "@/app/(dashboard)/(game)/game/new_batch/components/DialogBase";
import { FileRejection } from "react-dropzone";
import {
  determineState,
  renderEntry,
} from "@/app/(dashboard)/(game)/game/new_batch/utils/componentUtils";
import { handleOCRComputeErrors } from "@/app/services";

interface ScoresheetUploadAsyncProps {
  setResponse: any;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

function ScoresheetUploadAsync({
  setResponse,
  setIsLoading,
}: ScoresheetUploadAsyncProps) {
  const [file, setFile] = useState<File | null>(null);
  const router = useRouter();
  const proModal = useProModal();
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
  const [detectionsState, setDetectionsState] =
    useState<PossibleTooltipStates | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [boxes, setBoxes] = useState<any>(null);

  const pathname = usePathname();

  const [shouldLoadYolo, setShouldLoadYolo] = useState<boolean>(false);

  const {
    session,
    topk,
    iouThreshold,
    scoreThreshold,
    loadingHasFailed,
    isLoadingModels,
  } = useYolo({ shouldLoad: shouldLoadYolo });

  const origImageRef = useRef<HTMLImageElement | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  //toasts the successful loading of yolo
  useEffect(() => {
    if (session) {
      toast({
        description: "Models loaded",
        duration: 2000,
      });
    }
  }, [session]);

  useEffect(() => {
    if (loadingHasFailed) {
      toast({
        variant: "destructive",
        description: "Failed to load image validation models",
        duration: 2000,
      });
    }
  }, [loadingHasFailed]);

  //reads the accepted file and extracts the image source
  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      setImageSrc(e.target!.result as string);
    };

    reader.readAsDataURL(file);
  }, [file]);

  //yolo processing
  useEffect(() => {
    if (!session || !origImageRef.current || !imageSrc) {
      return;
    }

    if (loadingHasFailed) return;

    const processBoxes = () => {
      setIsProcessing(true);
      detectImage(
        origImageRef.current, // input image
        session,
        topk,
        iouThreshold,
        scoreThreshold,
      )
        .then(({ boxes }) => {
          setBoxes(boxes);
          setIsProcessing(false);
        })
        .catch((err) => console.error(err));

      if (origImageRef.current)
        //@ts-expect-error
        origImageRef.current.onload();
    };

    if (!boxes) processBoxes();
  }, [
    origImageRef.current,
    session,
    topk,
    iouThreshold,
    scoreThreshold,
    boxes,
    imageSrc,
    loadingHasFailed,
  ]);

  useEffect(() => {
    if (!boxes) return;

    if (loadingHasFailed) return;

    const state = determineState(boxes.length, yoloType.cell);

    if (!state) return;

    setDetectionsState(state);

    if (state !== "good") setIsModalOpen(true);
  }, [boxes]);

  const submitFn = () => {
    setIsModalOpen(false);
    return submit();
  };

  const submit = async () => {
    try {
      if (!file) {
        toast({
          variant: "destructive",
          description: "No file selected",
          duration: 3000,
        });
        return;
      }
      setResponse(undefined);
      setIsLoading(true);
      setIsSubmitting(true);

      const formData = new FormData();
      formData.append("image", file);

      const { data } = await axios.post("/api/game/upload", formData);
      router.push(`/${data.id}`);
      setIsLoading(false);

      toast({
        description: "Success",
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      setResponse(null);
      setIsLoading(false);
      handleOCRComputeErrors(error as AxiosError, proModal);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const shouldSubmitWithoutValidationWarnings = detectionsState === "good";
    const shouldSubmitWithoutModelChecking = loadingHasFailed && file;
    const shouldSubmit =
      shouldSubmitWithoutValidationWarnings || shouldSubmitWithoutModelChecking;

    if (shouldSubmit) submit();
  }, [detectionsState, loadingHasFailed, file]);

  const onFilesAccepted = (file: File) => {
    if (!shouldLoadYolo && !session) {
      setShouldLoadYolo(true);
      toast({
        description: "Loading models for analysis",
        duration: 2000,
      });
    }
    //reset processing state
    setBoxes(null);
    setDetectionsState(null);
    setFile(file);
  };

  const onFilesRejected = (file: FileRejection) => {
    toast({
      variant: "destructive",
      description: "File is not compatible",
      duration: 3000,
    });
  };

  const problem: ProblemType | null = detectionsState
    ? {
        icon: tooltipsIconsAndTextsCell[detectionsState]!.icon,
        description: tooltipsIconsAndTextsCell[detectionsState]!.text,
        fileName: file?.name ?? "Unknown",
      }
    : null;

  if (pathname.includes("game/new_batch")) return <></>;

  return (
    <>
      <img ref={origImageRef} className="hidden w-max h-max" src={imageSrc} />
      <ModalProblems
        submitFn={submitFn}
        isOpen={isModalOpen}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        setIsOpen={setIsModalOpen}
      >
        {problem && renderEntry(problem, "1")}
      </ModalProblems>
      <ImageUpload
        isLoadingModels={isLoadingModels}
        isSubmitting={isSubmitting}
        onFilesAccepted={onFilesAccepted}
        onFilesRejected={onFilesRejected}
      />
    </>
  );
}

const ModalProblems = ({
  isOpen,
  isSubmitting,
  setIsOpen,
  children,
  submitFn,
}: {
  isOpen: boolean;
  isSubmitting: boolean;
  children?: React.ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  submitFn: () => Promise<void>;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <DialogBase
      isSubmitting={isSubmitting}
      submitFn={submitFn}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {children}
    </DialogBase>
  );
};

export default ScoresheetUploadAsync;
