import { breakpointsCells, breakpointsTable } from "./constants";
import { ProblemType, yoloType } from "./types";

export const renderEntry = (entry: ProblemType, key: string) => {
  let make_key = (i: number) => `${key}-${i}`;
  return (
    <div className="flex items-center gap-x-4 " key={make_key(1)}>
      <div className="text-md flex gap-2" key={make_key(2)}>
        <div key={make_key(3)}>{entry.icon}</div>
        <div key={key} className="font-semibold break-all w-44">
          {entry.fileName}
        </div>
        <div key={make_key(4)}>{entry.description}</div>
      </div>
    </div>
  );
};

export const determineState = (
  count: string | number | null,
  type: yoloType,
) => {
  if (count === null || count === undefined) return undefined;

  let countToInt = parseInt(count.toString(), 10);

  if (type === yoloType.cell) {
    if (countToInt >= breakpointsCells.good) return "good";
    else if (countToInt >= breakpointsCells.okay) return "okay";
    else return "bad";
  }

  if (type === yoloType.table) {
    //setup like this mainly for setting up
    //the base for extending the table problems
    //to be different from the cell problems
    if (countToInt >= breakpointsTable.tooMany) return "tooMany";
    if (countToInt === breakpointsTable.good) return "good";
    else if (countToInt >= breakpointsTable.okay) return "okay";
    else return "bad";
  }
};
