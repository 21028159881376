import React from "react";

export const SvgDiamond: React.FC = () => (
  <svg
    height="20px"
    width="30px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <polygon
      style={{ fill: "#C3F6F9" }}
      points="256,499.47 512,146.167 414.217,12.53 97.784,12.53 0.001,146.167"
    />
    <g>
      <polygon
        style={{ fill: "#D1F9F7" }}
        points="97.786,12.53 170.663,146.172 0,146.172"
      />
      <polygon
        style={{ fill: "#D1F9F7" }}
        points="414.217,12.53 341.327,146.172 255.995,12.53"
      />
      <polygon
        style={{ fill: "#D1F9F7" }}
        points="341.327,146.172 255.995,499.467 170.663,146.172"
      />
    </g>
    <g>
      <polygon
        style={{ fill: "#9EE7E7" }}
        points="414.217,12.53 511.99,146.172 341.327,146.172"
      />
      <polygon
        style={{ fill: "#9EE7E7" }}
        points="255.995,12.53 341.327,146.172 170.663,146.172"
      />
      <polygon
        style={{ fill: "#9EE7E7" }}
        points="170.663,146.172 255.995,499.467 0,146.172"
      />
    </g>
  </svg>
);
