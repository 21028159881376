"use client";

import { Move, initializeSelectedMove } from "@/types/Move";

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import { toast } from "@/components/ui/use-toast";
import { GameSchema, GameSchemaPayload } from "@/lib/validators/game-schema";
import axios from "axios";
import { useRouter } from "next/navigation";
import { ClientUser } from "@/types/ClientUser";
import ScoresheetUploadAsync from "@/components/ScoresheetUploadAsync";

// images
// :
// boxes
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped_boxes.png"
// cropped
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped.png"
// edges
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/cropped_edges.png"
// final_chess_moves
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/final_chess_moves.png"
// original
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage1/3e1b0702-360e-4823-b9ac-a42b9603506f/original.png"
// output
// :
// "/uploads/2023-10-23/3e1b0702-360e-4823-b9ac-a42b9603506f/stage2/3e1b0702-360e-4823-b9ac-a42b9603506f/output.png"

interface ChessboardUploadProps {
  user: ClientUser | null;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export default function ChessboardUpload({
  user,
  setIsLoading,
}: ChessboardUploadProps) {
  const [ocrResponse, setOcrResponse] = useState<any>(null);
  const router = useRouter();

  const saveGame = async (ocrResponse: any) => {
    try {
      const game: GameSchemaPayload = GameSchema.parse({
        uuid: ocrResponse.uuid,
        detections: ocrResponse.detections,
        images: JSON.stringify(ocrResponse.images),
        pgn: ocrResponse.pgn,
        has_both_players: ocrResponse.has_both_players ?? false,
      });

      const { data } = await axios.post("/api/game", game);

      router.refresh();
      router.push(`/${data.id}`);
    } catch (error) {
      console.log(error);
      toast({
        variant: "destructive",
        description: "Something went wrong.",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    if (!ocrResponse) {
      return;
    }

    (ocrResponse.detections as Move[]).map((move: Move, idx: number) => {
      initializeSelectedMove(move);
    });

    saveGame(ocrResponse);
  }, [ocrResponse]);

  return (
    <>
      <section className="flex flex-col mx-2 md:flex-row  md:space-x-2 items-center">
        {user && (
          <ScoresheetUploadAsync
            setResponse={setOcrResponse}
            setIsLoading={setIsLoading}
          />
        )}
      </section>
    </>
  );
}
