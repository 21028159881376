import { AxiosError } from "axios";
import { toast } from "@/components/ui/use-toast";
import { useProModalStore } from "@/hooks/useProModal";

export const handleOCRComputeErrors = (
  error: AxiosError,
  proModal: useProModalStore,
) => {
  if ((error as AxiosError).response?.status === 402) {
    proModal.onOpen();
    return;
  }

  toast({
    variant: "destructive",
    description: "Something went wrong.",
    duration: 3000,
  });
};
