import { z } from "zod";

export const ChessAiUploadFormSchema = z.object({
  humanPgn: z
    .string()
    .min(2, {
      message: "PGN is required.",
    })
    .max(10000, {
      message: "PGN is required.",
    })
    .optional(),

  white: z
    .string()
    .min(2, {
      message: "minimum length 2",
    })
    .max(255, {
      message: "max length 256.",
    })
    .optional(),

  black: z
    .string()
    .min(2, {
      message: "minimum length 2",
    })
    .max(255, {
      message: "max length 256.",
    })
    .optional(),

  date: z.coerce
    .date({
      required_error: "A date is required.",
    })
    .optional(),

  tournamentId: z.string().optional(),

  comments: z
    .string()
    .min(2, {
      message: "Min Comment.",
    })
    .max(1024, {
      message: "Comment too long.",
    })
    .optional(),
});

export type TChessAiUploadFormSchema = z.infer<typeof ChessAiUploadFormSchema>;
