import axios from "axios";
export const download = (url: string) => {
  return new Promise((resolve, reject) => {
    const config = {
      responseType: "arraybuffer" as "arraybuffer",
    };

    axios
      .get(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject({
          status: error.response?.status,
          statusText: error.response?.statusText,
        });
      });
  });
};
