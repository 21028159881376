import { z } from "zod";
import { ChessAiUploadFormSchema } from "./chess-ai-upload-form-schema";

export const GameSchema = z.object({
  uuid: z.string().min(1, {
    message: "uuid is required.",
  }),

  pgn: z.string().min(0, {
    message: "pgn is required.",
  }),

  originalGameId: z.string().optional(),

  name: z
    .string()
    .min(2, {
      message: "minimum length 2",
    })
    .max(255, {
      message: "max length 256.",
    })
    .optional(),

  comments: z
    .string()
    .min(0, {
      message: "Min Comment.",
    })
    .max(1024, {
      message: "Max comment.",
    })
    .optional()
    .nullable(),
  has_both_players: z.boolean().default(false).nullable(),
  images: z.string().optional(),
  detections: z.array(z.any()),
  humanPgn: z.string().optional().nullable(),
  gameSource: z.enum(["IMAGE", "PGN"]).catch((e) => "IMAGE"),
});

export const PartialGameSchema = GameSchema.partial();

export const GameSchemaNew = GameSchema.extend({
  images: z.string(),
});

export const GameTournamentSchema = PartialGameSchema.merge(
  z.object({
    tournamentId: z.string(),
  }),
);

export const GameNameFormSchema = GameSchema.merge(
  ChessAiUploadFormSchema.omit({ comments: true, humanPgn: true }),
);

export type GameSchemaPayload = z.infer<typeof GameSchema>;
export type PartialGameSchemaPayload = z.infer<typeof PartialGameSchema>;
export type GameTournamentSchemaPayload = z.infer<typeof GameTournamentSchema>;
export type TGameNameFormSchema = z.infer<typeof GameNameFormSchema>;
