import { FaCheckCircle } from "react-icons/fa";
import { TooltipDataType } from "./types";
import { PiShieldWarningLight, PiWarningCircleLight } from "react-icons/pi";
import { IoWarningSharp } from "react-icons/io5";

export const tooltipsIconsAndTextsCell: TooltipDataType = {
  good: {
    icon: <FaCheckCircle className="text-green-600 h-4 w-4" />,
    text: "Good number of cell detections.",
  },
  okay: {
    icon: <PiWarningCircleLight className="text-yellow-600 h-4 w-4" />,
    text: "Decent number of cell detections.",
  },
  bad: {
    icon: <IoWarningSharp className="text-red-600 h-4 w-4" />,
    text: "Could not detect enough cells. Make sure the image is properly lit, taken from a good angle.",
  },
};

export const tooptipsIconAndTextsTable: TooltipDataType = {
  good: {
    icon: <FaCheckCircle className="text-green-600 h-4 w-4" />,
    text: "Good number of table detections.",
  },
  okay: {
    icon: <PiWarningCircleLight className="text-yellow-600 h-4 w-4" />,
    text: "Decent number of table detections.",
  },
  bad: {
    icon: <IoWarningSharp className="text-red-600 h-4 w-4" />,
    text: "Could not detect enough tables. Make sure the image is properly lit, taken from a good angle.",
  },
  tooMany: {
    icon: <PiShieldWarningLight className="text-blue-600 h-5 w-5" />,
    text: "Too many tables detected. Detections are better if you send one PGN sheet per image",
  },
};

export const breakpointsCells = {
  good: 120,
  okay: 80,
};

export const breakpointsTable = {
  tooMany: 3, //starting at 3 it's too many tables, done like this for extensibility
  good: 2,
  okay: 1,
};
