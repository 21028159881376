import { Tournament } from "@/prisma/schema/mysql";
import { ClientUser } from "@/types";
import { RefObject } from "react";

export type Id = string | number;

export enum ImageType {
  WHITE,
  BLACK,
}

export type ImageTuple = {
  file: File;
  imageType: ImageType;
  id: string;
};

//upload form no memo.tsx
export interface UploadFormRevisedProps {
  tournaments: Tournament[];
  user: ClientUser | null;
}

export type CanvasAndImageRefsType = {
  [imageId: string]: {
    imageRefs: Array<RefObject<HTMLImageElement>>;
    canvasRefForYolo: RefObject<HTMLCanvasElement>;
    canvasRefForYoloTable: RefObject<HTMLCanvasElement>;
  };
};

export type CanvasAndImageRefObjects = {
  imageRefs: Array<RefObject<HTMLImageElement>>;
  canvasRefForYolo: RefObject<HTMLCanvasElement>;
  canvasRefForYoloTable: RefObject<HTMLCanvasElement>;
  canvasToDrawOnYolo: RefObject<HTMLCanvasElement>;
  canvasTodrawOnYoloTable: RefObject<HTMLCanvasElement>;
};

export type ProblemType = {
  description: string;
  icon: React.ReactNode;
  fileName: string;
};

// export type ProblemTypeForSpecificModel =
// {cell:
//   ProblemType[]} |
//   {table: ProblemType[]}

export type ImageTupleWithoutColor = Omit<ImageTuple, "imageType">;

export type ProblemMap = Map<string, ProblemType[]>;

//
export enum yoloType {
  "cell",
  "table",
}

export type PossibleTooltipStates = "good" | "okay" | "bad" | "tooMany";

export type TooltipDataType = {
  [type in PossibleTooltipStates]?: { icon: JSX.Element; text: string };
};
